<!--
 * @Author: mulingyuer
 * @Date: 2021-09-02 17:51:44
 * @LastEditTime: 2021-09-02 18:20:48
 * @LastEditors: mulingyuer
 * @Description: 设置
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\views\settings.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="setting">
    <div class="content" v-loading="loading">
      <el-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
        label-width="140px"
        size="medium"
        class="medium-form"
      >

      <div class="modular">
          <h2 class="modular-title">问答按钮</h2>
          <div class="modular-content">
            <el-form-item label="按钮名称：" prop="button_name">
              <el-input v-model="form.button_name"></el-input>
            </el-form-item>
        
          </div>
        </div>
        <div class="modular">
          <h2 class="modular-title">审核设置</h2>
          <div class="modular-content">
            <el-form-item label="发帖审核：" prop="check.post">
              <el-radio-group v-model="form.check.post">
                <el-radio :label="1">先发后审</el-radio>
                <el-radio :label="0">先审后发</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="评论审核：" prop="check.comment">
              <el-radio-group v-model="form.check.comment">
                <el-radio :label="1">先发后审</el-radio>
                <el-radio :label="0">先审后发</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>

        <div class="modular">
          <h2 class="modular-title">官方账号设置</h2>
          <div class="modular-content">
            <el-form-item label="账号名称：" prop="admin_account.name">
              <el-input v-model="form.admin_account.name"></el-input>
            </el-form-item>
            <el-form-item label="管理员：" prop="admin_account.avatar">

              <ImageItem
                v-model="form.admin_account.avatar"
                width="96px"
                height="96px"
              />
            </el-form-item>
          </div>
        </div>
        <div class="modular">
          <h2 class="modular-title">分享配置</h2>
          <div class="modular-content">
            <el-form-item label="分享标题：" prop="share.title">
              <el-input v-model="form.share.title"></el-input>
            </el-form-item>
            <el-form-item label="分享图片：" prop="share.image">

              <ImageItem
                v-model="form.share.image"
                :width="150"
                :height="120"
              />
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <FixedActionBar>
      <el-button
        v-if="!loading"
        type="primary"
        size="medium"
        :loading="saveLoading"
        @click="onSave('ruleForm')"
        >保存</el-button
      >
    </FixedActionBar>
  </div>
</template>

<script>
import ImageItem from '@/modules/common/components/ImageItem.vue'
import FixedActionBar from '@/base/layout/FixedActionBar'
import { detail, save } from '@/modules/consult/api/consult-content-config.js'
export default {
  data() {
    return {
      loading: false, //加载中
      form: {
        button_name: '',
        check: {
          post: 0,
          comment: 0,
        },
        admin_account: {
          name: '管理员',
          avatar: '',
        },
        share: {
          title: '',
          image: '',
        },
      },
      rules: {
        'admin_account.name': [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
      },
      editData: {}, //编辑的数据
      saveLoading: false, //保存中
    }
  },
  methods: {
    //获取详情
    getDetail() {
      this.loading = true
      detail()
        .then((res) => {
          const { data } = res
          this.editData = data
          this.initForm()

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    //初始化表单
    initForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.editData[key]
      })
    },
    //保存
    onSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          const postData = { config: this.form }
          save(postData)
            .then((res) => {
              const { msg } = res
              this.$message.success(msg)

              this.saveLoading = false
            })
            .catch(() => {
              this.saveLoading = false
            })
        }
      })
    },
  },
  created() {
    this.getDetail()
  },
  components: {
    ImageItem,
    FixedActionBar,
  },
}
</script>

<style lang="scss" scoped></style>
../api/consult-content-config
